import React from 'react';
import { Accordion } from 'semantic-ui-react';

export default [
  {
    title: 'My intro is too fast, is there a way to slow down?',
    content: (
      <Accordion.Content>
        <p>
          The text speed is adjusted to the text length
          to fit with the music and animation duration.
        </p>
        <p>So less text makes it slower and more text will make it faster.</p>
        <p>If the text speed is changed the music will not fit well.</p>
        <p>
          Please try to remove some text to make it slower.
          You can reduce line breaks, for instance,
          they take more space and makes the text goes faster.
        </p>
      </Accordion.Content>
    ),
  },
  {
    title: 'How the rendered video will look like?',
    content: (
      <Accordion.Content>
        <p>
          The videos are rendered on a 1280 x 720 resolution for default,
          but you can pay for Full HD quality (1920 x 1080).
          The video file is in MP4 format and you should have
          a video player and a device that is capable to reproduce this media format.
        </p>
        <p>
          We do not guarantee that the rendered videos are identical
          to the creations in this service.
          The quality may differ depending on the device on which the content is displayed.
        </p>
        <p>
          You can see a sample of a video rendered here:
          {' '}
          <a href="https://www.youtube.com/watch?v=lYnLLF2QyM4">https://www.youtube.com/watch?v=lYnLLF2QyM4</a>
        </p>
      </Accordion.Content>
    ),
  },
  {
    title: 'How can I change the Death Star image?',
    content: `You can request to change the Death Star image for your custom video.
    In the download page, you can pay for the Death Star customization and you will receive an email to reply with your the image you want.
    It will take more time to change the image but you will receive two videos, one with the Death Star and another one with your image customized.`,
  },
  {
    title: 'How long takes to customize Death Star image in my video?',
    content: `After your payment, you should receive an email with instructions to send your customized image.
      It will take more hours for us to adjust the image to your video.
      It should be ready in less than one business day.`,
  },
  {
    title: 'My video will have the Watermark as in the preview?',
    content: `No, the watermark is not present in rendered videos on Star Wars Intro Creator.`,
  },
];
