import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}><MDXTag name="h2" components={components}>{`7. Star Wars Intro Creator`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`7.1.`}</MDXTag>{` This website is not related or to Lucasfilm Ltd., Walt Disney, or Twentieth Century Fox.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`7.2.`}</MDXTag>{` The music and the Star Wars logo are copyrights of Lucasfilm Ltd.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`7.3.`}</MDXTag>{` Rendered videos on Star Wars Intro Creator will not have the watermark.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`8. Video customizations`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`8.1.`}</MDXTag>{` By default, videos are delivered in HD quality (resolution 1280x720)`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`8.2.`}</MDXTag>{` To receive the video earlier the payment must be in the exact value or greater than `}<MDXTag name="strong" components={components} parentName="p">{`10 US Dollars`}</MDXTag>{`.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`8.3.`}</MDXTag>{` For `}<MDXTag name="strong" components={components} parentName="p">{`15 US Dollars`}</MDXTag>{` or more the video will be rendered in Full HD resolution (1920x1080).`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`8.4.`}</MDXTag>{` For `}<MDXTag name="strong" components={components} parentName="p">{`40 US Dollars`}</MDXTag>{` you can customize the Death Star image and the video will be in Full HD quality. Contact us after your payment to send your image to be used on the video.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`8.5.`}</MDXTag>{` You can see a sample of a video rendered here: `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.youtube.com/watch?v=lYnLLF2QyM4"
        }}>{`https://www.youtube.com/watch?v=lYnLLF2QyM4`}</MDXTag></MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      