import React from 'react';
import ProductPageLayout from '../components/ProductPageLayout';

import faq from '../faq/starwars';
import Terms from '../termsOfService/starwars.mdx';

const SecondPage = () => (
  <ProductPageLayout
    title="Star Wars Intro Creator"
    emailAlias="StarWars"
    website="https://starwarsintrocreator.kassellabs.io"
    faq={faq}
    terms={<Terms />}
  />
);

export default SecondPage;
